import { getToken } from "./auth.js";
import SETTINGS from "../../settings.json";
export default class OpenPlanner {
  static cache = {};
  static async getEvents(cache = true) {
    if (cache && this.cache.events) {
      return this.cache.events;
    }
    const token = await getToken();
    const response = await fetch(SETTINGS.backend_url + "eventsByUser", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        mode: "no-cors",
      },
    });
    const data = await response.json();
    this.cache.events = data;
    return data;
  }

  static async getCourses(cache = true) {
    if (cache && this.cache.courses) {
      return this.cache.courses;
    } else {
      console.log(cache, this.cache.courses);
    }
    const token = await getToken();
    const response = await fetch(SETTINGS.backend_url + "calendar", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        mode: "no-cors",
      },
    });
    const data = await response.json();
    this.cache.courses = data;
    return data;
  }

  static async getCourseEvents(syllabusID) {
    const token = await getToken();
    const response = await fetch(
      SETTINGS.backend_url + "events?syllabusID=" + syllabusID,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
          mode: "no-cors",
        },
      }
    );
    const data = await response.json();
    return data;
  }

  static async addEvent(event) {
    const token = await getToken();
    const response = await fetch(SETTINGS.backend_url + "event", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        mode: "no-cors",
      },
      body: JSON.stringify({
        start: event.start,
        end: event.end,
        title: event.title,
        body: event.body,
        syllabusID: event.calendar,
      }),
    });
    const data = await response.text();
    return data;
  }

  static async getExportICS() {
    const token = await getToken();
    const response = await fetch(SETTINGS.backend_url + "exportICS", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
        mode: "no-cors",
      },
    });
    return response;
  }

  static async updateEvent(event) {
    const token = await getToken();
    const response = await fetch(SETTINGS.backend_url + "event", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        start: event.start,
        end: event.end,
        title: event.title,
        body: event.body,
        isCompleted: event.isCompleted,
        syllabusID: event.calendar,
        eventID: event.id,
      }),
    });
    const data = await response.json();
    return data;
  }

  static async uploadSyllabus(file) {
    const formData = new FormData();
    formData.append("file", file);
    const token = await getToken();
    const response = await fetch(SETTINGS.backend_url + "syllabus", {
      method: "POST",
      headers: {
        Authorization: `${token}`,
        mode: "no-cors",
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  }

  static async deleteSyllabus(id) {
    const token = await getToken();
    const response = await fetch(
      SETTINGS.backend_url + "syllabus?syllabusID=" + id,
      {
        method: "DELETE",
        headers: {
          Authorization: `${token}`,
          mode: "no-cors",
        },
      }
    );
    const data = await response.json();
    return data;
  }

  static async deleteEvent(id) {
    const token = await getToken();
    const response = await fetch(
      SETTINGS.backend_url + "syllabus?syllabusID=" + id,
      {
        method: "DELETE",
        headers: {
          Authorization: `${token}`,
          mode: "no-cors",
        },
      }
    );
    const data = await response.json();
    return data;
  }
}
